.top-card{
    position: relative;
    overflow: hidden;
    &::before{
        content: '""';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 50%;
        background: linear-gradient(to bottom right, blue, darkblue);
        z-index: 0;
    }
}


.top-card-content{
    z-index: 1;
    position: relative;
    padding: 20px;
}


.top-card-contacts{
    background: linear-gradient(102deg, #00617f 64%, rgba(137, 211, 41, 1) 64%, #10384f 0%);
}

.top-card-activities{
    background: linear-gradient(102deg, #2b6636 64%, rgb(137, 211, 41) 64%, #004422 0%);
}

.top-card-tracking{
    background: linear-gradient(102deg, #624963 64%, rgb(137, 211, 41) 64%, #443247 0%);
}

.top-card-payout{
    background: linear-gradient(102deg, #de0043 64%, rgb(137, 211, 41) 64%, #d3054b 0%);
}

/* Base styles for the arrow icons */
.arrow-icon {
    transition: transform 0.3s ease-in-out; /* Smooth animation */
    display: inline-flex; /* Allows for vertical alignment */
    align-items: center; /* Centers vertically */
  }
  
  /* Up arrow animation */
  .arrow-icon.up {
    transform: translateY(0); /* Start at original position */
  }
  
  .arrow-icon.up.active {
    transform: translateY(-3px); /* Move up slightly when active */
  }
  
  /* Down arrow animation */
  .arrow-icon.down {
    transform: translateY(0); /* Start at original position */
  }
  
  .arrow-icon.down.active {
    transform: translateY(3px); /* Move down slightly when active */
  }

  .chartWrapper {
    position: relative;
    width: 100%;
    overflow-x: scroll;
  }
  .chartAreaWrapper {
    width: auto /* Adjust width as needed */
  }